<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Profile Personal Information-->
        <div class="d-flex flex-row">
          <!--begin::Aside-->
          <div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
            <!--begin::Profile Card-->
            <div class="card card-custom card-stretch">
              <!--begin::Body-->
              <div class="card-body pt-4">
                <!--begin::Toolbar-->

                <!--end::Toolbar-->
                <!--begin::User-->
                <div class="d-flex align-items-center">
                  <div
                    class="
                      symbol symbol-60 symbol-xxl-100
                      mr-5
                      align-self-start align-self-xxl-center
                    "
                  >
                    <div
                      class="symbol-label"
                      style="background-image: url('assets/media/users/300_21.jpg')"
                    ></div>
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div>
                    <a
                      href="#"
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                      >{{ profile.second_name }} {{ profile.name }}</a
                    >
                    <div class="text-muted">Профиль клиента</div>
                  </div>
                </div>
                <!--end::User-->
                <!--begin::Contact-->
                <div class="py-9">
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="font-weight-bold mr-2">Email:</span>
                    <a href="#" class="text-muted text-hover-primary">{{ profile.email }}</a>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="font-weight-bold mr-2">Телефон:</span>
                    <span class="text-muted">{{ profile.phone }}</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="font-weight-bold mr-2">Адрес:</span>
                    <span class="text-muted">{{ profile.address }}</span>
                  </div>
                </div>
                <!--end::Contact-->
                <!--begin::Nav-->
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div class="navi-item mb-2">
                    <router-link :to="{ name: 'profile' }" class="navi-link py-4">
                      <span class="navi-icon mr-2">
                        <span class="svg-icon">
                          <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              ></path>
                              <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              ></path>
                            </g>
                          </svg>
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                      <span class="navi-text font-size-lg">Контактная Информация</span>
                    </router-link>
                  </div>
                  <div class="navi-item mb-2">
                    <a href="#" class="navi-link py-4 active">
                      <span class="navi-icon mr-2">
                        <span class="svg-icon">
                          <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg-->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                fill="#000000"
                                opacity="0.3"
                              ></path>
                              <path
                                d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                fill="#000000"
                                opacity="0.3"
                              ></path>
                              <path
                                d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                fill="#000000"
                                opacity="0.3"
                              ></path>
                            </g>
                          </svg>
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                      <span class="navi-text font-size-lg">ЭЦП</span>
                    </a>
                  </div>
                  <div class="navi-item mb-2">
                    <a href="#" @click.prevent="logout" class="navi-link py-4">
                      <span class="navi-icon mr-2">
                        <span class="svg-icon"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Navigation\Sign-out.svg-->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                                transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) "
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) "
                                x="13"
                                y="6"
                                width="2"
                                height="12"
                                rx="1"
                              />
                              <path
                                d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) "
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                      </span>
                      <span class="navi-text">Выход</span>
                    </a>
                  </div>
                </div>
                <!--end::Nav-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Profile Card-->
          </div>
          <!--end::Aside-->
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">
            <!--begin::Card-->
            <div class="card card-custom card-stretch">
              <!--begin::Header-->
              <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">ЭЦП</h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1"
                    >Смена пинкода доступа к ЭЦП</span
                  >
                </div>
                <div class="card-toolbar">
                  <button type="button" class="btn btn-success mr-2" @click.prevent="changePin">
                    Сохранить
                  </button>
                  <button type="button" class="btn btn-secondary" @click.prevent="resetMe">
                    Отменить
                  </button>
                </div>
              </div>

              <!--end::Header-->
              <!--begin::Form-->
              <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                  <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mb-6">Смена пинкода доступа к ЭЦП</h5>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Текущий пин код</label>
                    <div class="col-lg-9 col-xl-6">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        v-model="old_pin_code"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Новый пин код</label>
                    <div class="col-lg-9 col-xl-6">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        v-model="new_pin_code"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label"></label>
                    <div class="col-lg-9 col-xl-6">
                      <button
                        class="btn btn-success"
                        type="button"
                        id="button-addon2"
                        @click.prevent="changePin"
                      >
                        Сменить пин код
                      </button>
                    </div>
                  </div>
                </div>
                <!--end::Body-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Profile Personal Information-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { ref, computed } from "vue";
import api from "@/core/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { auth } from "@/core/services/auth";

export default {
  components: {},
  setup: function () {
    const old_pin_code = ref("");
    const new_pin_code = ref("");
    const socket = ref(null);
    const cb = ref(undefined);

    const profile = ref({
      is_email_confirmed: true,
      is_phone_confirmed: true,
    });

    const router = useRouter();
    const store = useStore();

    const resetMe = () => {
      api
        .getUser()
        .then((resp) => {
          profile.value = resp.data.data;
        })
        .catch(() => {
          router.push({ name: "login" });
        });
    };

    const tokenState = () => {
      console.log("tokenState");
      const request = {
        command: "token_state",
        callback: "qqq",
      };

      socket.value.send(JSON.stringify(request));
    };

    const changePin = () => {
      console.log("Change Pin:" + new_pin_code.value);
      if (socket.value.readyState !== 1) {
        alert("Нужно установить сервис работы с Ключами");
        return;
      }

      const auth = {
        command: "set_pin",
        parameters: {
          old_pin: old_pin_code.value,
          new_pin: new_pin_code.value,
        },
      };

      cb.value = (response) => {
        if (response.success) {
          alert("Пин изменен");
          old_pin_code.value = "";
          new_pin_code.value = "";
        }
      };

      socket.value.send(JSON.stringify(auth));
    };

    const logout = () => {
      auth.logOut();
    };

    const connectToServer = () => {
      socket.value = new WebSocket("ws://127.0.0.1:8090/");
      console.log("WebSocket Connecting...");
      socket.value.onopen = () => {
        console.log("WebSocket Connected OK");
        tokenState();
      };

      socket.value.onmessage = (event) => {
        let resp = JSON.parse(event.data);
        console.log(resp);
        console.log("WS Callback", cb.value);

        let callback = cb.value;
        cb.value = undefined;
        if (callback != null && typeof callback !== "undefined") {
          callback(resp);
        } else if (resp.token_connected) {
          console.log("WebSocket Ready");
        } else if (!resp.token_connected) {
          alert("Нужно подключить USB Ключ Jakarta");
        }
      };

      socket.value.onclose = function () {
        alert("Вебсокет Закрыт, обновите страницу");
        console.log("WebSocket Disconnected");
      };
    };

    return {
      old_pin_code,
      new_pin_code,
      socket,
      connectToServer,
      logout,
      profile,
      resetMe,
      changePin,

      // core$main - название модуля в /core/stores/index.js
      setGlobalToken: (socket) => store.commit("core$main/setGlobalToken", socket),
      globalToken: computed(() => store.getters["core$main/globalToken"]),
    };
  },
  beforeUnmount() {
    console.log("close socket");
    if (this.socket && this.socket.readyState === 0) {
      this.socket.close();
    }
  },
  beforeMount() {
    this.resetMe();
    setTimeout(this.connectToServer, 1000);
  },
};
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
